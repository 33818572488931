@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Circular", sans-serif;
}

.cutom-sidebar-scrollbar ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px;
  background-color: rgba(55, 65, 81, 1);
}
.cutom-sidebar-scrollbar ::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: rgba(17, 24, 39, 1);
}
.cutom-sidebar-scrollbar ::-webkit-scrollbar-thumb {
  background-color: rgba(55, 65, 81, 1);
  border-radius: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.react-datepicker-wrapper {
  width: 100%;
}

.multiselect input[type="text"]:focus {
  outline: none;
  box-shadow: none;
}

/* removing default label on phone input  */
.phone-input-custom .special-label {
  display: none;
}

#search::-webkit-search-cancel-button {
  cursor: pointer;
}
